import { FavoriteIcon } from '@geovelo-frontends/commons';
import { ArrowForward, Favorite } from '@mui/icons-material';
import { Box, DialogProps, Divider, SvgIcon, SvgIconProps, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import { AppStoreBadge, Button, GooglePlayBadge } from '../../components';
import SideDialog from '../../components/side-dialog';
import { environment } from '../../environment';

function AppDialog({
  ...props
}: Omit<DialogProps, 'onClose'> & { onClose: () => void }): JSX.Element {
  const { t } = useTranslation();

  return (
    <SideDialog {...props} title={t('companies.app_dialog.title')}>
      <>
        <Typography>{t('companies.app_dialog.description')}</Typography>
        <Box
          alignSelf="flex-start"
          bgcolor="#FFF5D0"
          borderRadius={2}
          display="flex"
          gap={5}
          padding={2}
        >
          <Rating caption="Apple Store" Icon={FavoriteIcon} value="4,6 / 5" />
          <Rating caption="Google Play" Icon={FavoriteIcon} value="4,5 / 5" />
          <Rating caption={t('commons.stats_types.downloads')} Icon={Favorite} value="1,5 M" />
        </Box>
        <Box alignItems="flex-start" display="flex" gap={5}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography fontWeight={700} variant="body2">
              {t('companies.app_dialog.actions.download')}
            </Typography>
            <AppStoreBadge height={52} />
            <GooglePlayBadge height={49.5} />
          </Box>
          <Box alignSelf="stretch" borderLeft="1px solid rgba(0, 0, 0, 0.12)" />
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography fontWeight={700} variant="body2">
              {t('companies.app_dialog.actions.scan_code')}
            </Typography>
            <Box height={124} width={124}>
              <QRCode
                fgColor="#005A44"
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={`${environment.frontendUrl}/download?utm_source=geovelo-entreprise`}
                viewBox={`0 0 256 256`}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Button
            color="primary"
            component="a"
            endIcon={<ArrowForward />}
            href={`${environment.frontendUrl}/our-mission`}
            rel="noreferrer"
            size="medium"
            target="_blank"
            variant="text"
          >
            {t('companies.app_dialog.actions.go_to_mission')}
          </Button>
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography fontWeight={700} variant="h6">
            {t('companies.app_dialog.how_to.title')}
          </Typography>
          <Typography component="ul">
            <Trans
              components={[<li key={0} />, <li key={1} />, <li key={2} />]}
              i18nKey="companies.app_dialog.how_to.description"
            />
          </Typography>
        </Box>
      </>
    </SideDialog>
  );
}

function Rating({
  Icon,
  value,
  caption,
}: {
  caption: string;
  Icon: ((props: SvgIconProps) => JSX.Element) | typeof SvgIcon;
  value: string;
}): JSX.Element {
  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Box alignItems="center" display="flex" gap={1}>
        <Icon fontSize="small" sx={{ color: '#FCCE2C' }} />
        <Typography fontWeight={700}>{value}</Typography>
      </Box>
      <Typography color="#2C2E30" variant="body2">
        {caption}
      </Typography>
    </Box>
  );
}

export default AppDialog;
