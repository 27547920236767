import { LockIcon, TPartnerContract } from '@geovelo-frontends/commons';
import { DiamondOutlined } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import moment from 'moment';

import { AnnouncementIcon } from './icons';

const now = moment();

export function PremiumBadge({
  light,
  isTry,
  disableDaysCount,
  contract,
  offer,
}: {
  contract: TPartnerContract;
  disableDaysCount?: boolean;
  isTry?: boolean;
  light?: boolean;
  offer?: 'premium';
}): JSX.Element {
  return isTry ? (
    <Tooltip
      placement="bottom"
      title={
        contract.contractTemplate.code === 'geovelo-entreprise-trial-period'
          ? `À la fin de votre période d'essai, cette fonctionnalité ne sera plus disponible, sauf dans ${offer === 'premium' ? "l'offre Premium" : 'les offres payantes'}.`
          : contract.contractTemplate.code === 'geovelo-entreprise-free'
            ? "Cette fonctionnalité n'est disponible que dans les offres payantes"
            : ''
      }
    >
      <Box alignItems="center" display="flex" gap={1}>
        {!disableDaysCount && (
          <Box alignItems="center" display="flex" gap={1}>
            <AnnouncementIcon fontSize="small" sx={{ color: '#743AEF' }} />
            <Typography variant="body2">
              {contract.endDateTime?.diff(now, 'days', false)}j d'essai
            </Typography>
          </Box>
        )}
        <Box
          alignItems="center"
          bgcolor={light ? '#fff' : '#EAE2FC'}
          borderRadius={2}
          display="flex"
          gap={0.5}
          paddingX={1}
          paddingY={0.5}
        >
          <DiamondOutlined sx={{ fill: '#743AEF', height: 14, width: 14 }} />
          <Typography color="#743AEF" variant="caption">
            Fonctionnalité premium
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  ) : (
    <Box
      alignItems="center"
      bgcolor={light ? '#fff' : '#EAE2FC'}
      borderRadius={2}
      display="flex"
      gap={0.5}
      justifyContent="center"
      padding={1}
    >
      <LockIcon sx={{ color: '#743AEF', height: 14, width: 14 }} />
      <Typography color="#743AEF" lineHeight={1} variant="caption">
        Disponible en version payante
      </Typography>
    </Box>
  );
}
