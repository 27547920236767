import {
  Autocomplete,
  GeocoderService,
  GeogroupService,
  Place,
  TUserPlaceType,
  UserPlace,
  UserService,
  createNewImageMarker,
  useCancellablePromise,
  useGeoveloMap,
  userPlaceTypes,
} from '@geovelo-frontends/commons';
import { Box, CircularProgress, DialogProps, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../app/context';
import companyIcon from '../assets/img/favorite-company.svg';

import { Button } from './button';
import { Dialog } from './dialog';

import { MapMouseEvent, Marker } from '!maplibre-gl';

const mapId = 'place-map';

export const placeTypes = { ...userPlaceTypes, company: { color: '#dd428d', icon: companyIcon } };

function PlaceDialog({
  placeType,
  currentPlace,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  currentPlace: UserPlace | Place | null;
  onClose: (place?: UserPlace | Place) => void;
  placeType: Extract<TUserPlaceType, 'home' | 'work'> | 'company';
}): JSX.Element {
  const [updatedPlace, updatePlace] = useState<UserPlace | Place | null>(null);
  const [updated, setUpdated] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const markerRef = useRef<Marker | null>(null);
  const {
    partner: { current: currentPartner, geogroup },
    user: { home, works },
    actions: { setPartnerGeogroup, setUserHome, setUserWorks },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    if (props.open) {
      updatePlace(currentPlace?.clone() || null);
      setUpdated(false);
      setSubmitting(false);
    }
  }, [props.open]);

  useEffect(() => {
    async function reverseGeocode() {
      if (updatedPlace && !updatedPlace.addressDetail) {
        try {
          const newPlace = await cancellablePromise(
            GeocoderService.reverseGeocode(undefined, updatedPlace.point),
          );
          updatePlace(newPlace);
        } catch (err) {
          console.error(err);
        }
      }
    }

    reverseGeocode();

    return () => cancelPromises();
  }, [updatedPlace]);

  async function handleSubmit() {
    if (placeType === 'company') {
      if (!currentPartner || !geogroup || !updatedPlace) return;

      setSubmitting(true);
      markerRef.current?.setDraggable(false);

      try {
        const updatedGeogroup = await cancellablePromise(
          GeogroupService.updateGeogroup(geogroup.id, { address: updatedPlace }, currentPartner),
        );

        setPartnerGeogroup(updatedGeogroup);
        enqueueSnackbar(t('companies.pages.admin.company.data.general_data_form.success'));
        props.onClose();
      } catch (err) {
        setSubmitting(false);
        markerRef.current?.setDraggable(true);
        enqueueSnackbar(t('companies.pages.admin.company.data.general_data_form.server_error'), {
          variant: 'error',
        });
      }
    } else {
      const currentUserPlace =
        placeType === 'home'
          ? home
          : works
            ? works.find(({ id }) => currentPlace?.id === id) || null
            : undefined;

      if (!geogroup || currentUserPlace === undefined || !updatedPlace) return;

      const { point, address } = updatedPlace;

      setSubmitting(true);
      markerRef.current?.setDraggable(false);

      try {
        let newPlace: UserPlace | undefined;
        if (currentUserPlace) {
          if (updated) {
            const { id } = currentUserPlace;
            if (typeof id === 'number') {
              newPlace = await UserService.updatePlace(id, {
                point,
                address,
                title: t(userPlaceTypes[placeType].titleKey || ''),
              });

              if (placeType === 'home') setUserHome(newPlace);
              else if (works) {
                const newWorks = [...works];
                const index = newWorks.findIndex((work) => id === work.id);
                if (index > -1) {
                  newWorks.splice(index, 1, newPlace);
                  setUserWorks(newWorks);
                }
              }
            }
          }
        } else {
          newPlace = await UserService.addPlace({
            type: placeType,
            point,
            address,
            title: t(userPlaceTypes[placeType].titleKey || ''),
          });

          if (placeType === 'home') setUserHome(newPlace);
          else if (works) {
            setUserWorks([...works, newPlace]);
          }
        }

        enqueueSnackbar(t('companies.pages.admin.user.address_form.success'));
        props.onClose(newPlace);
      } catch (err) {
        setSubmitting(false);
        markerRef.current?.setDraggable(true);
        enqueueSnackbar(t('companies.pages.admin.user.address_form.server_error'), {
          variant: 'error',
        });
      }
    }
  }

  return (
    <Dialog fullWidth loading={isSubmitting} maxWidth="sm" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          {placeType === 'company'
            ? t('companies.pages.admin.company.data.address_form.title')
            : t('companies.pages.admin.user.address_form.title', { context: placeType, count: 1 })}
        </Typography>
        {placeType !== 'company' && (
          <Typography variant="body2">
            {t('companies.pages.admin.user.address_form.description')}
          </Typography>
        )}
        <PlaceForm
          currentPlace={currentPlace}
          initialized={props.open}
          isSubmitting={isSubmitting}
          markerRef={markerRef}
          placeType={placeType}
          setUpdated={setUpdated}
          updatedPlace={updatedPlace}
          updatePlace={updatePlace}
        />
        <Box alignItems="center" display="flex" gap={2} justifyContent="flex-end">
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={() => props.onClose()}
            variant="outlined"
          >
            {t('commons.actions.cancel')}
          </Button>
          <Button
            color="primary"
            disabled={!updatedPlace || isSubmitting}
            onClick={handleSubmit}
            startIcon={isSubmitting && <CircularProgress color="inherit" size={16} thickness={4} />}
            variant="contained"
          >
            {t('commons.actions.validate')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export function PlaceForm({
  initialized: _initialized,
  isSubmitting,
  placeType,
  currentPlace,
  updatedPlace,
  markerRef,
  setUpdated,
  updatePlace,
}: {
  currentPlace: UserPlace | Place | null;
  initialized: boolean;
  isSubmitting: boolean;
  markerRef: MutableRefObject<Marker | null>;
  placeType: Extract<TUserPlaceType, 'home' | 'work'> | 'company';
  setUpdated?: (updated: boolean) => void;
  updatedPlace: UserPlace | Place | null;
  updatePlace: (place: Place | null) => void;
}): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const {
    partner: { geogroup, sites },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const {
    map,
    init: initMap,
    destroy: destroyMap,
  } = useGeoveloMap({
    smallDevice: true,
    baseLayer: 'geovelo',
  });

  useEffect(() => {
    if (_initialized) setInitialized(true);
  }, [_initialized]);

  useEffect(() => {
    if (initialized) {
      initMap({
        container: mapId,
        customZoomControls: true,
        center: currentPlace
          ? { lat: currentPlace.point.coordinates[1], lng: currentPlace.point.coordinates[0] }
          : geogroup?.place
            ? { lat: geogroup.place.point.coordinates[1], lng: geogroup.place.point.coordinates[0] }
            : undefined,
        zoom: currentPlace || geogroup?.place ? 15 : undefined,
      });
    }

    return () => {
      markerRef.current = null;
      destroyMap();
    };
  }, [initialized]);

  useEffect(() => {
    function handleClick({ lngLat: { lat, lng } }: MapMouseEvent) {
      updatePlace(new Place(undefined, { type: 'Point', coordinates: [lng, lat] }));
    }

    if (!updatedPlace) map?.on('click', handleClick);

    if (map && updatedPlace) {
      if (markerRef.current) {
        markerRef.current?.setLngLat({
          lat: updatedPlace.point.coordinates[1],
          lng: updatedPlace.point.coordinates[0],
        });
      } else {
        const { icon, color } = placeTypes[placeType];
        markerRef.current = createNewImageMarker({
          color,
          icon,
          draggable: true,
        })
          .setLngLat({
            lat: updatedPlace.point.coordinates[1],
            lng: updatedPlace.point.coordinates[0],
          })
          .on('dragend', ({ target }: { target: Marker }) => {
            map.flyTo({ center: target.getLngLat(), zoom: Math.max(15, map.getZoom()) });
            updatePlace(
              new Place(undefined, {
                type: 'Point',
                coordinates: target.getLngLat().toArray(),
              }),
            );
            setUpdated?.(true);
          })
          .addTo(map);
      }
    } else {
      markerRef.current?.remove();
      markerRef.current = null;
    }

    return () => {
      map?.off('click', handleClick);
    };
  }, [map, updatedPlace]);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography fontWeight={700} variant="body2">
          {t('companies.pages.admin.user.address_form.labels_address', {
            context: placeType,
          })}
        </Typography>
        <Autocomplete
          disableFloatingLabel
          customOptions={
            placeType === 'work'
              ? sites && sites.length === 0
                ? geogroup?.place
                  ? [geogroup.place]
                  : []
                : sites?.reduce<Place[]>((res, { place }) => {
                    if (place) res.push(place);
                    return res;
                  }, [])
              : undefined
          }
          defaultValue={updatedPlace}
          disabled={isSubmitting}
          label="Ex: 16 rue Saint-Merri 75004 Paris"
          margin="none"
          onSelect={(place) => {
            updatePlace(place);
            setUpdated?.(true);
            if (map && place) {
              const [lng, lat] = place.point.coordinates;
              map.flyTo({
                animate: false,
                center: { lat, lng },
                zoom: Math.max(15, map.getZoom()),
              });
            }
          }}
          size="small"
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography fontWeight={700} variant="body2">
          {t('companies.pages.admin.user.address_form.labels_map')}
        </Typography>
        <Box height={200} id={mapId} />
      </Box>
    </Box>
  );
}

export default PlaceDialog;
