import { GeoveloIcon } from '@geovelo-frontends/commons';
import { Avatar, Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import illu from '../../assets/img/login-illu.svg';
import { AppInlineIcon, AppInlineWhiteIcon } from '../../components';
import ImpersonatingBanner from '../../components/impersonating-banner';
import useImpersonating from '../../hooks/impersonating';

import BringTogether from './bring-together';
import Steps from './steps';

export const registerStep = { key: 'register', label: 'Création de compte' };

export const loginStep = { key: 'login', label: 'Connexion au compte Geovelo' };

export const onboardingSteps = [
  { key: 'personal-data', label: 'Informations personnelles' },
  { key: 'address', label: 'Adresse' },
];

export const onboardingTeamsStep = { key: 'teams', label: 'Équipes' };

export const addCompanySteps = [
  { key: 'personal-data', label: 'Informations personnelles' },
  { key: 'company-data', label: "Informations de l'entreprise" },
  { key: 'sites', label: 'Ajout de sites de travail', optional: true },
  { key: 'members', label: 'Ajout de membres', optional: true },
];

function LoginLayout({
  isSSO,
  leftPanelType,
  isWebview,
  centered,
  partnerIcon,
  steps,
  stepIndex,
  backButton,
  title,
  children,
}: {
  backButton?: ReactNode;
  centered?: boolean;
  children?: ReactNode;
  isSSO?: boolean;
  isWebview?: boolean;
  leftPanelType: 'bringTogether' | 'stepper' | 'default';
  partnerIcon?: ReactNode | null;
  stepIndex?: number;
  steps?: Array<{ key: string; label: ReactNode }>;
  title: ReactNode;
}): JSX.Element {
  const { t } = useTranslation();
  const { impersonating } = useImpersonating();

  return (
    <Box flexGrow={1} sx={{ overflowY: 'auto' }}>
      {impersonating && <ImpersonatingBanner />}
      <Box
        bgcolor={isSSO ? '#f3f6ff' : undefined}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        minHeight="100%"
      >
        {!isSSO && (
          <Box
            alignItems="center"
            color="#fff"
            display={{ xs: 'none', md: isWebview ? 'none' : 'flex' }}
            flexDirection="column"
            gap={6}
            sx={{
              background: 'linear-gradient(135deg, #326EBF 0%, #326AC2 38%, #2DA599 100%)',
            }}
            width="50%"
          >
            {leftPanelType === 'bringTogether' ? (
              <BringTogether />
            ) : leftPanelType === 'stepper' ? (
              <Steps currentStepIndex={stepIndex || 0} items={steps || []} />
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                minHeight="max(100svh, 100%)"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  flexGrow={1}
                  gap={3}
                  justifyContent="center"
                  paddingX={{ xs: 5, lg: 8 }}
                  paddingY={5}
                >
                  <AppInlineWhiteIcon sx={{ height: 34, width: (34 / 35) * 261 }} />
                  <Typography color="#fff" fontSize="1.15rem" fontWeight={600}>
                    Tous en selle pour la transition écologique !
                  </Typography>
                </Box>
                <Box
                  height={300}
                  sx={{
                    backgroundImage: `url(${illu})`,
                    backgroundPosition: 'bottom left',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                  }}
                />
              </Box>
            )}
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          justifyContent={isSSO ? 'center' : undefined}
          width={{ md: isWebview ? '100%' : isSSO ? '100%' : '50%' }}
        >
          <Box
            alignSelf="center"
            bgcolor={isSSO ? '#fff' : undefined}
            borderRadius={isSSO ? 6 : 0}
            display="flex"
            flexDirection="column"
            flexGrow={isSSO ? 0 : 1}
            gap={5}
            justifyContent={{
              xs: centered ? 'center' : 'flex-start',
              md: isWebview && !centered ? 'flex-start' : 'center',
            }}
            maxWidth="calc(100% - 96px)"
            paddingX={isSSO ? 5 : 0}
            paddingY={isSSO ? 5 : 3}
            width={isSSO ? 600 : 578}
          >
            {!isWebview && (
              <>
                {partnerIcon ? (
                  typeof partnerIcon === 'string' ? (
                    <Avatar
                      imgProps={{ sx: { objectFit: 'contain', height: '90%', width: '90%' } }}
                      src={partnerIcon}
                      sx={{ border: '1px solid #e3e7ee', height: 54, width: 54 }}
                    />
                  ) : (
                    partnerIcon
                  )
                ) : isSSO ? (
                  <GeoveloIcon sx={{ height: 40, width: 40 }} />
                ) : (
                  <AppInlineIcon sx={{ height: 35, width: 261 }} />
                )}
              </>
            )}
            <Box display="flex" flexDirection="column" gap={1}>
              {steps && (
                <Typography color="textSecondary" variant="body2">
                  {t('companies.step', {
                    stepIndex: (stepIndex || 0) + 1,
                    stepsCount: steps.length,
                  })}
                </Typography>
              )}
              <Box alignItems="flex-start" display="flex" gap={2}>
                {backButton}
                <Typography component="h1" fontWeight={800} variant="h5">
                  {title}
                </Typography>
              </Box>
            </Box>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LoginLayout;
