import {
  AuthService,
  CalendarIcon,
  CompanyIcon,
  HomeOutlinedIcon,
  LockIcon,
  PeopleIcon,
  PersonIcon,
} from '@geovelo-frontends/commons';
import { ChatOutlined, FolderOpen, MoreHoriz } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Drawer as MuiDrawer,
  Popover,
  Skeleton,
  SvgIcon,
  SvgIconProps,
  Toolbar,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import moment from 'moment';
import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, NavLinkProps } from 'react-router-dom';

import { AppContext, getStartedAdminSteps, getStartedEmployeeSteps } from '../../app/context';
import {
  AddMemberIcon,
  AnnouncementIcon,
  AppInlineIcon,
  ChevronRightIcon,
  ConfigIcon,
  CupIcon,
  CyclabilityIcon,
  EditIcon,
  FMDIcon,
  HelpIcon,
  LeaderboardIcon,
  ListIcon,
  LogoutIcon,
  StatsIcon,
  SurveyIcon,
} from '../../components';
import { FlagIcon } from '../../components/icons/flag';
import { environment } from '../../environment';
import useCrisp from '../../hooks/crisp';
import RestrictAccessDialog from '../../pages/admin/employees/restrict-access-dialog';

const now = moment();
export const drawerWidth = 280;

function Drawer({
  openOnMobile,
  toggle,
}: {
  openOnMobile: boolean;
  toggle: () => void;
}): JSX.Element {
  return (
    <Box flexShrink={{ sm: 0 }} width={{ sm: drawerWidth }}>
      <MuiDrawer
        anchor="top"
        ModalProps={{
          keepMounted: true,
        }}
        onClose={toggle}
        open={openOnMobile}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            backgroundColor: '#fff',
            borderRight: 0,
            boxSizing: 'border-box',
            color: '#212121',
            height: '100%',
            width: { xs: '100%', sm: drawerWidth },
          },
        }}
        variant="temporary"
      >
        <DrawerContent toggle={toggle} />
      </MuiDrawer>
      <MuiDrawer
        open
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            backgroundColor: '#fff',
            borderRight: '1px solid #E3E7EE',
            boxSizing: 'border-box',
            color: '#212121',
            width: drawerWidth,
          },
        }}
        variant="permanent"
      >
        <DrawerContent />
      </MuiDrawer>
    </Box>
  );
}

const linkKeys = [
  'getStarted',
  'home',
  'challenges',
  'leaderboards',
  'stats',
  'fmdConfig',
  'fmd',
  'fmdEmployee',
  'fmdCertificates',
  'fmdInfo',
  'accessibility',
  'mobilitySurvey',
] as const;

type TlinkKey = (typeof linkKeys)[number];

const linkLists: Array<{
  key: string;
  items: Array<{
    Icon: ((props: SvgIconProps) => JSX.Element) | typeof SvgIcon;
    key: TlinkKey;
    titleKey: string;
    to: string;
  }>;
  titleKey: string;
}> = [
  {
    key: 'animation',
    titleKey: 'companies.navigation.animation',
    items: [
      {
        key: 'challenges',
        to: '/challenges',
        titleKey: 'companies.navigation.challenges',
        Icon: CupIcon,
      },
      {
        key: 'leaderboards',
        to: '/leaderboards',
        titleKey: 'companies.navigation.leaderboards',
        Icon: LeaderboardIcon,
      },
    ],
  },
  {
    key: 'fmd',
    titleKey: 'companies.navigation.fmd',
    items: [
      {
        key: 'fmdConfig',
        to: '/bicycle-compensation/config',
        titleKey: 'companies.navigation.fmd_config',
        Icon: EditIcon,
      },
      {
        key: 'fmdCertificates',
        to: '/bicycle-compensation/certificates',
        titleKey: 'companies.navigation.bicycle_compensation_certificates',
        Icon: ListIcon,
      },
      {
        key: 'fmdInfo',
        to: '/bicycle-compensation/config',
        titleKey: 'companies.navigation.fmd_info',
        Icon: FMDIcon,
      },
      {
        key: 'fmd',
        to: '/bicycle-compensation',
        titleKey: 'companies.navigation.bicycle_compensation',
        Icon: CalendarIcon,
      },
      {
        key: 'fmdEmployee',
        to: '/bicycle-compensation',
        titleKey: 'companies.navigation.bicycle_compensation_employee',
        Icon: FMDIcon,
      },
    ],
  },
  {
    key: 'data',
    titleKey: 'companies.navigation.data',
    items: [
      {
        key: 'stats',
        to: '/stats',
        titleKey: 'companies.navigation.stats',
        Icon: StatsIcon,
      },
      {
        key: 'mobilitySurvey',
        to: '/mobility-survey',
        titleKey: 'companies.navigation.mobility_survey',
        Icon: SurveyIcon,
      },
      {
        key: 'accessibility',
        to: '/accessibility',
        titleKey: 'companies.navigation.cyclability',
        Icon: CyclabilityIcon,
      },
    ],
  },
];

function DrawerContent({ toggle }: { toggle?: () => void }): JSX.Element {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const [signingOut, setSigningOut] = useState(false);
  const [restrictAccessDialogOpen, openRestrictAccessDialog] = useState(false);
  const {
    partner: {
      current: currentPartner,
      contract,
      permissions,
      fmdConfig,
      getStartedProgression: getStartedAdminProgression,
    },
    user: { current: currentUser, employee, getStartedProgression: getStartedEmployeeProgression },
    actions: { setCurrentUser },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { initialized: crispInitialized, showChat } = useCrisp();

  async function handleSignOut() {
    setSigningOut(true);

    try {
      await AuthService.signOut();
      setCurrentUser(null);
    } catch (err) {
      console.error(err);
      setSigningOut(false);
    }
  }

  const primaryTypographyProps: TypographyProps<'span', { component: 'div' }> = {
    alignItems: 'center',
    component: 'div',
    display: 'flex',
    gap: 1,
    variant: 'body2',
  };

  const displayGetStartedEmployeeLink =
    employee?.isCollaborator &&
    getStartedEmployeeProgression &&
    !getStartedEmployeeProgression.allDone;

  const displayGetStartedAdminLink =
    employee?.isAdminOrAnimator &&
    getStartedAdminProgression &&
    !getStartedAdminProgression.allDone;

  return (
    <>
      <Toolbar sx={{ gap: 1 }}>
        {currentPartner?.icon ? (
          <Avatar src={currentPartner.icon} />
        ) : (
          <Avatar sx={({ palette }) => ({ bgcolor: palette.primary.main })}>
            <CompanyIcon />
          </Avatar>
        )}
        <Typography noWrap fontWeight={700} title={currentPartner?.title}>
          {currentPartner?.title || <Skeleton variant="text" width={100} />}
        </Typography>
      </Toolbar>
      <Box display="flex" flexDirection="column" flexGrow={1} gap={2}>
        <Box flexGrow={1} paddingX={2}>
          <List
            disablePadding
            component="nav"
            sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
          >
            {displayGetStartedEmployeeLink && (
              <CustomListItemButton onClick={toggle} to="/get-started">
                <ListItemIcon sx={{ color: 'inherit', minWidth: 40 }}>
                  <FlagIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('companies.navigation.get_started', {
                    context: 'status',
                    count: getStartedEmployeeSteps.filter(
                      (key) => !getStartedEmployeeProgression.ignoredSteps[key],
                    ).length,
                    doneCount: getStartedEmployeeProgression.doneCount,
                  })}
                  primaryTypographyProps={primaryTypographyProps}
                />
                <ListItemSecondaryAction>
                  <ChevronRightIcon color="primary" />
                </ListItemSecondaryAction>
              </CustomListItemButton>
            )}
            {displayGetStartedAdminLink && (
              <CustomListItemButton onClick={toggle} to="/get-started">
                <ListItemIcon sx={{ color: 'inherit', minWidth: 40 }}>
                  <FlagIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('companies.navigation.get_started', {
                    context: 'status',
                    count: getStartedAdminSteps[getStartedAdminProgression.type].filter(
                      (key) => !getStartedAdminProgression.ignoredSteps[key],
                    ).length,
                    doneCount: getStartedAdminProgression.doneCount,
                  })}
                  primaryTypographyProps={primaryTypographyProps}
                />
                <ListItemIcon
                  className="chevron"
                  sx={{ color: 'inherit', justifyContent: 'flex-end', minWidth: 24 }}
                >
                  <ChevronRightIcon color="primary" sx={{ height: 16, width: 16 }} />
                </ListItemIcon>
              </CustomListItemButton>
            )}
            <CustomListItemButton onClick={toggle} to="/">
              <ListItemIcon sx={{ color: 'inherit', minWidth: 40 }}>
                <HomeOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={t('companies.navigation.home')}
                primaryTypographyProps={primaryTypographyProps}
              />
              <ListItemIcon
                className="chevron"
                sx={{ color: 'inherit', justifyContent: 'flex-end', minWidth: 24 }}
              >
                <ChevronRightIcon color="primary" sx={{ height: 16, width: 16 }} />
              </ListItemIcon>
            </CustomListItemButton>
            {linkLists.map(({ key: listKey, titleKey: listTitleKey, items }) => {
              if (employee?.isAnimator && listKey === 'fmd' && !fmdConfig)
                return <Fragment key={listKey} />;

              return (
                <List
                  disablePadding
                  component="div"
                  key={listKey}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    marginTop: employee?.isCollaborator ? 0 : 2,
                  }}
                >
                  {!employee?.isCollaborator && (
                    <ListSubheader
                      disableSticky
                      sx={{ fontSize: '0.75rem', lineHeight: 'initial' }}
                    >
                      {t(listTitleKey).toUpperCase()}
                    </ListSubheader>
                  )}
                  {items.map(({ key, to, Icon, titleKey }) => {
                    let premium = false;
                    let restricted = false;

                    if (key === 'leaderboards') {
                      if (!permissions.jerseysEnabled) {
                        if (!employee?.isAdmin) return <Fragment key={key} />;
                        restricted = true;
                      }
                    } else if (key === 'fmdConfig') {
                      if (!permissions.fmdEnabled || !employee?.isAdmin || fmdConfig !== null)
                        return <Fragment key={key} />;
                    } else if (key === 'fmd') {
                      if (!permissions.fmdEnabled || !fmdConfig || employee?.isCollaborator)
                        return <Fragment key={key} />;
                    } else if (key === 'fmdEmployee') {
                      if (!permissions.fmdEnabled || !fmdConfig || !employee?.isCollaborator)
                        return <Fragment key={key} />;
                    } else if (key === 'fmdCertificates') {
                      if (!employee?.isAdmin || !fmdConfig) return <Fragment key={key} />;
                      if (!permissions.fmdEnabled) restricted = true;
                    } else if (key === 'fmdInfo') {
                      if (permissions.fmdEnabled || fmdConfig !== null)
                        return <Fragment key={key} />;
                      restricted = true;
                    } else if (key === 'mobilitySurvey') {
                      if (!employee?.isAdmin) return <Fragment key={key} />;
                      else if (!permissions.mobilitySurveyEnabled) premium = true;
                      else if (!permissions.mobilitySurveyResponsesReadEnabled) restricted = true;
                    } else if (key === 'accessibility') {
                      if (!permissions.accessibilityEnabled) return <Fragment key={key} />;

                      if (
                        employee?.isAdmin &&
                        contract?.contractTemplate.code === 'geovelo-entreprise-free'
                      ) {
                        restricted = true;
                      }
                    }

                    return (
                      <CustomListItemButton key={key} onClick={toggle} premium={premium} to={to}>
                        <ListItemIcon sx={{ color: 'inherit', minWidth: 40 }}>
                          <Icon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <>
                              <span>{t(titleKey)}</span>
                              {(premium || restricted) && (
                                <LockIcon sx={{ color: '#905EFA', height: 14, width: 14 }} />
                              )}
                            </>
                          }
                          primaryTypographyProps={primaryTypographyProps}
                        />
                        <ListItemIcon
                          className="chevron"
                          sx={{ color: 'inherit', justifyContent: 'flex-end', minWidth: 24 }}
                        >
                          <ChevronRightIcon color="primary" sx={{ height: 16, width: 16 }} />
                        </ListItemIcon>
                      </CustomListItemButton>
                    );
                  })}
                </List>
              );
            })}
          </List>
        </Box>
        <Box paddingX={2}>
          <List
            disablePadding
            component="nav"
            sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
          >
            {employee?.isAdmin && (
              <>
                <Box position="relative">
                  <ListItemButton
                    color="inherit"
                    component={NavLink}
                    onClick={toggle}
                    sx={() => ({
                      borderRadius: 2,
                      '.MuiListItemIcon-root': { opacity: 0.8 },
                      paddingRight: '54px',
                    })}
                    to="/admin/employees"
                  >
                    <ListItemIcon sx={{ color: 'inherit', minWidth: 40 }}>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t('companies.pages.admin.company.navigation.employees')}
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItemButton>
                  <Box position="absolute" right="16px" top="7px">
                    <Tooltip
                      placement="right"
                      title={t('companies.pages.admin.company.add_employee.title')}
                    >
                      <IconButton
                        component={Link}
                        size="small"
                        sx={{
                          bgcolor: 'rgba(217, 231, 255, 0.52)',
                          border: '1px solid #D9E7FF',
                          '&:hover': { bgcolor: 'rgba(217, 231, 255, 0.32)' },
                        }}
                        to="/admin/add-members"
                      >
                        <AddMemberIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </>
            )}
            <ListItemButton
              color="inherit"
              onClick={({ currentTarget }) => setHelpMenuAnchorEl(currentTarget)}
              sx={() => ({
                borderRadius: 2,
                '.MuiListItemIcon-root': { opacity: 0.8 },
              })}
            >
              <ListItemIcon sx={{ color: 'inherit', minWidth: 40 }}>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText
                primary={t('companies.navigation.help')}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItemButton>
            {employee?.isAdmin &&
              contract &&
              (contract.contractTemplate.code === 'geovelo-entreprise-free' ||
                contract.contractTemplate.code === 'geovelo-entreprise-trial-period') && (
                <ListItemButton
                  component={NavLink}
                  onClick={toggle}
                  sx={{
                    bgcolor: '#EAE2FC !important',
                    borderRadius: 2,
                    minHeight: 48,
                    paddingX: 1.5,
                    paddingY: 0,
                  }}
                  to="/admin/offers"
                >
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <AnnouncementIcon fontSize="small" sx={{ color: '#743AEF' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('companies.actions.choose_offer')}
                    primaryTypographyProps={{ variant: 'body2' }}
                  />
                  <ListItemSecondaryAction sx={{ right: 12 }}>
                    {contract.contractTemplate.code === 'geovelo-entreprise-trial-period' ? (
                      <Chip
                        label={t('companies.trial_banner.days_left', {
                          count: contract.endDateTime?.diff(now, 'days', false),
                        })}
                        sx={{
                          '&&': {
                            bgcolor: '#D0BAFF',
                            borderRadius: 2,
                            color: '#521CC5',
                            fontSize: '0.75rem',
                            height: 20,
                            span: { paddingX: 0.5 },
                          },
                        }}
                      />
                    ) : (
                      contract.contractTemplate.code === 'geovelo-entreprise-free' && (
                        <Chip
                          label="Gratuit"
                          sx={{
                            '&&': {
                              bgcolor: '#D0BAFF',
                              borderRadius: 2,
                              color: '#521CC5',
                              fontSize: '0.75rem',
                              height: 20,
                              span: { paddingX: 1 },
                            },
                          }}
                        />
                      )
                    )}
                  </ListItemSecondaryAction>
                </ListItemButton>
              )}
            <ListItemButton
              color="inherit"
              onClick={({ currentTarget }) => setUserMenuAnchorEl(currentTarget)}
              sx={{
                border: '1px solid #DDE0E7',
                borderRadius: 2,
                minHeight: 62,
                paddingX: 1,
                paddingY: 0.5,
                '.MuiListItemIcon-root': { opacity: 0.8 },
              }}
            >
              <ListItemIcon sx={{ color: 'inherit', minWidth: 40 }}>
                {currentUser && employee ? (
                  currentUser?.profilePicture ? (
                    <Avatar src={currentUser.profilePicture} sx={{ height: 32, width: 32 }} />
                  ) : (
                    <Avatar
                      sx={({ palette }) => ({
                        bgcolor: '#EEF9F4',
                        color: palette.primary.main,
                        fontSize: '1.1rem',
                        height: 32,
                        width: 32,
                      })}
                    >
                      {employee.firstName || employee.lastName
                        ? [employee.firstName, employee.lastName]
                            .filter(Boolean)
                            .map((part) => part?.[0].toUpperCase())
                            .join('')
                        : currentUser.username?.[0].toUpperCase()}
                    </Avatar>
                  )
                ) : (
                  <Skeleton height={32} variant="circular" width={32} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  currentUser && employee ? (
                    employee.name || currentUser.username
                  ) : (
                    <Skeleton variant="text" width={100} />
                  )
                }
                primaryTypographyProps={{ noWrap: true, variant: 'body2' }}
                secondary={
                  employee?.isAdminOrAnimator
                    ? t(
                        employee.isAnimator
                          ? 'companies.profile_types.animator'
                          : 'companies.profile_types.admin',
                      )
                    : undefined
                }
                secondaryTypographyProps={{ noWrap: true, variant: 'caption' }}
              />
              <ListItemSecondaryAction sx={{ height: 20 }}>
                <MoreHoriz fontSize="small" />
              </ListItemSecondaryAction>
            </ListItemButton>
          </List>
        </Box>
      </Box>
      <Box alignItems="center" display="flex" height={64} justifyContent="center" paddingX={2}>
        <AppInlineIcon sx={{ height: 28, width: (28 / 35) * 261 }} />
      </Box>
      <Menu
        anchorEl={helpMenuAnchorEl}
        onClose={() => setHelpMenuAnchorEl(null)}
        open={Boolean(helpMenuAnchorEl)}
        slotProps={{
          root: { sx: { '.MuiBackdrop-root': { bgcolor: 'initial' } } },
          paper: { sx: { borderRadius: 2 } },
        }}
      >
        <MenuItem
          dense
          component="a"
          href={environment.faqLinks.home}
          onClick={() => setHelpMenuAnchorEl(null)}
          rel="noreferrer"
          target="_blank"
        >
          <ListItemIcon>
            <SurveyIcon />
          </ListItemIcon>
          <ListItemText primary="FAQ" />
        </MenuItem>
        <MenuItem
          dense
          component="a"
          href="https://drive.google.com/drive/folders/1-iM4h7dZPiK-HJwifGt2Z_cT_z2oKEbk"
          onClick={() => setHelpMenuAnchorEl(null)}
          rel="noreferrer"
          target="_blank"
        >
          <ListItemIcon>
            <FolderOpen />
          </ListItemIcon>
          <ListItemText primary={t('companies.navigation.communication_kit')} />
        </MenuItem>
        <MenuItem
          dense
          disabled={!crispInitialized}
          onClick={() => {
            showChat();
            setHelpMenuAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ChatOutlined />
          </ListItemIcon>
          <ListItemText primary={t('companies.navigation.contact_support')} />
        </MenuItem>
      </Menu>
      <Popover
        anchorEl={userMenuAnchorEl}
        onClose={() => setUserMenuAnchorEl(null)}
        open={Boolean(userMenuAnchorEl)}
        slotProps={{
          root: { sx: { '.MuiBackdrop-root': { bgcolor: 'initial' } } },
          paper: { sx: { borderRadius: 2, minWidth: 300 } },
        }}
      >
        <Box alignItems="center" display="flex" gap={2} justifyContent="space-between" padding={2}>
          <Typography variant="caption">
            {t('companies.navigation.my_account').toUpperCase()}
          </Typography>
          {employee?.isAdminOrAnimator ? (
            <Box
              alignItems="center"
              bgcolor="#FAE3EE"
              borderRadius={1}
              display="flex"
              paddingX={1}
              paddingY={0.5}
            >
              <Typography color="#C6377C" fontWeight={700} variant="caption">
                {t(
                  employee.isAnimator
                    ? 'companies.profile_types.animator'
                    : 'companies.profile_types.admin',
                )}
              </Typography>
            </Box>
          ) : (
            <Box
              alignItems="center"
              bgcolor="#E7F4FA"
              borderRadius={1}
              display="flex"
              paddingX={1}
              paddingY={0.5}
            >
              <Typography color="#1589BB" fontWeight={700} variant="caption">
                {t('companies.profile_types.user')}
              </Typography>
            </Box>
          )}
        </Box>
        {employee?.professionalEmail && (
          <Box paddingX={2}>
            <Typography color="textSecondary" variant="body2">
              {employee.professionalEmail}
            </Typography>
          </Box>
        )}
        <List>
          {employee?.isAdmin && (
            <ListItemButton
              dense
              component={NavLink}
              onClick={() => {
                setUserMenuAnchorEl(null);
                toggle?.();
              }}
              to="/admin"
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <ConfigIcon />
              </ListItemIcon>
              <ListItemText primary={t('companies.navigation.settings')} />
            </ListItemButton>
          )}
          <ListItemButton
            dense
            component={NavLink}
            onClick={() => {
              setUserMenuAnchorEl(null);
              toggle?.();
            }}
            to="/profile"
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={t('companies.navigation.my_profile')} />
          </ListItemButton>
          <ListItemButton dense disabled={signingOut} onClick={handleSignOut}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={t('companies.navigation.sign_out')} />
          </ListItemButton>
        </List>
      </Popover>
      <RestrictAccessDialog
        onClose={() => openRestrictAccessDialog(false)}
        open={restrictAccessDialogOpen}
      />
    </>
  );
}

function CustomListItemButton({
  premium,
  children,
  ...props
}: ListItemButtonProps & NavLinkProps & { premium?: boolean }): JSX.Element {
  if (premium) {
    return (
      <Tooltip placement="right" title="Abonnez-vous pour accéder à cette fonctionnalité">
        <ListItemButton
          disableRipple
          color="inherit"
          component="div"
          sx={{
            borderRadius: 2,
            paddingY: 0.5,
            '.MuiListItemIcon-root': { opacity: 0.8 },
            '.chevron': { display: 'none' },
          }}
        >
          {children}
        </ListItemButton>
      </Tooltip>
    );
  }

  return (
    <ListItemButton
      end
      color="inherit"
      component={NavLink}
      sx={({ palette }) => ({
        borderRadius: 2,
        paddingY: 0.5,
        '&.active': {
          bgcolor: '#F3F6FF',
          color: palette.primary.main,
        },
        '&:not(.active)': {
          '.MuiListItemIcon-root': { opacity: 0.8 },
          '.chevron': { display: 'none' },
        },
      })}
      {...props}
    >
      {children}
    </ListItemButton>
  );
}

export default Drawer;
