import { InfoOutlined, TheaterComedy } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from '../../app/context';
import { Button } from '../../components';
import useImpersonating from '../../hooks/impersonating';

const now = moment();

function Banner(): JSX.Element {
  const {
    partner: { contract },
    user: { current: currentUser, employee },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { impersonating } = useImpersonating();

  if (impersonating) {
    return (
      <Box
        alignItems="center"
        bgcolor="#FAE3EE"
        display="flex"
        gap={3}
        minHeight={60}
        paddingX={3}
        paddingY={1}
        width="100%"
      >
        <Box alignItems="center" display="flex" flexGrow={1} gap={2}>
          <TheaterComedy sx={{ color: '#DD428D' }} />
          <Typography variant="body2">
            Vous êtes connecté·e en tant que "{currentUser?.username}" ({currentUser?.email})
          </Typography>
        </Box>
        <Box
          alignItems={{ xs: 'stretch', md: 'center' }}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          gap={1}
        >
          <Button
            onClick={() => {
              try {
                localStorage.removeItem('impersonate_authorization_token');
                localStorage.removeItem('impersonate_user_id');

                window.open('/', '_self');
              } catch {
                console.error('localStorage access is denied');
              }
            }}
            size="medium"
            sx={{ color: '#DD428D', textDecoration: 'underline !important' }}
            variant="text"
          >
            Revenir à son propre compte
          </Button>
        </Box>
      </Box>
    );
  }

  const offer = contract?.contractTemplate.code;

  if (!offer) return <></>;

  return (
    <>
      {contract &&
        employee?.isAdmin &&
        (contract.contractTemplate.code === 'geovelo-entreprise-trial-period' ||
          contract.contractTemplate.code === 'geovelo-entreprise-free') && (
          <Box
            alignItems="center"
            bgcolor="#EAE2FC"
            borderRadius={2}
            display="flex"
            gap={3}
            minHeight={60}
            paddingX={3}
            paddingY={1}
            width="100%"
          >
            <Box alignItems="center" display="flex" flexGrow={1} gap={2}>
              <InfoOutlined sx={{ color: '#743AEF' }} />
              <Typography color="#2C2E30" variant="body2">
                {contract.contractTemplate.code === 'geovelo-entreprise-free'
                  ? t('companies.trial_banner.description_free')
                  : t('companies.trial_banner.description', {
                      count: contract.endDateTime?.diff(now, 'days', false),
                    })}
              </Typography>
            </Box>
            <Box
              alignItems={{ xs: 'stretch', md: 'center' }}
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              flexShrink={0}
              gap={1}
            >
              <Button
                component={Link}
                size="medium"
                sx={{ color: '#521CC5', textDecoration: 'underline !important' }}
                to="/admin/offers"
                variant="text"
              >
                Voir les offres
              </Button>
            </Box>
          </Box>
        )}
    </>
  );
}

export default Banner;
